import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

import { LAYOUT, TRANSITION_DURATION_MS } from "src/layout/constants"
import Hamburger from "src/components/Hamburger"
import { useMenu, useMounted, useScrolled } from "src/helpers/hooks"
import Logo from "src/svg/logo.svg"

interface MenuLink {
  slug: string
  title: string
}
interface Entry {
  id: "agency" | "activities" | "contact"
  title: string
  links: "activities" | MenuLink[]
}

const ENTRIES: Entry[] = [
  {
    id: "agency",
    title: "Agence",
    links: [
      { slug: "agenda", title: "Agenda" },
      { slug: "valeurs", title: "Valeurs" },
      { slug: "equipe", title: "Équipe" },
      { slug: "methodes", title: "Méthodes" },
      { slug: "missions", title: "Index des missions" },
    ],
  },
  {
    id: "activities",
    title: "Activités",
    links: "activities",
  },
  {
    id: "contact",
    title: "Contact",
    links: [
      { slug: "contact", title: "Nous contacter" },
      { slug: "rejoindre", title: "Nous rejoindre" },
    ],
  },
]

const Placeholder = styled.div`
  height: ${LAYOUT.headerExpanded}px;
`
const Container = styled.header<{ $mounted: boolean; $scrolled: boolean }>`
  position: fixed;
  z-index: 5;
  width: 100%;
  transition-duration: ${TRANSITION_DURATION_MS}ms;
  transition-timing-function: ease-in-out;
  transition-property: ${({ $mounted }) => ($mounted ? "background-color, height" : "background-color")};
  background-color: ${({ $mounted, theme }) => ($mounted ? theme.background : "transparent")};
  height: ${({ $scrolled }) => ($scrolled ? LAYOUT.headerCollapsed : LAYOUT.headerExpanded)}px;
`
const Content = styled.div`
  max-width: ${LAYOUT.maxWidth}px;
  margin: 0 auto;
  padding: ${LAYOUT.margin}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  > a {
    z-index: 1;
  }
`
const Menu = styled.div<{ $open: boolean }>`
  @media (min-width: 800px) {
    display: inline-block;
    margin-left: ${LAYOUT.margin}px;
    button::before {
      display: block;
      content: attr(data-title);
      font-weight: bold;
      overflow: hidden;
      height: 0;
      visibility: hidden;
    }
  }
  @media (max-width: 799px) {
    padding: 0 10px 5px 65px;
  }
  @media (max-width: 349px) {
    padding-left: 10px;
  }
  position: relative;
  z-index: 10;
  button {
    font-weight: ${({ $open }) => ($open ? "bold" : "normal")};
    color: ${({ $open, theme }) => ($open ? theme.menu.active : theme.menu.inactive)};
    height: 30px;
    border: 2px solid ${({ $open, theme }) => ($open ? theme.menu.active : "transparent")};
  }
  button,
  a {
    text-transform: uppercase;
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding-inline-start: 0;
    display: ${({ $open }) => ($open ? "block" : "none")};
    margin: 0 0 5px;
    @media (min-width: 800px) {
      position: absolute;
      top: 30px;
      left: 0;
    }
    @media (max-width: 1300px) {
      left: initial;
      right: 0;
    }
    background-color: ${({ theme }) => theme.background};
    li {
      border: 2px solid ${({ theme }) => theme.menu.active};
      margin: -2px 0;
      a {
        display: block;
        white-space: nowrap;
        padding: 4px 6px;
        font-size: 0.7em;
        font-weight: bold;
        color: ${({ theme }) => theme.menu.active};
        &:hover,
        &.active {
          background-color: ${({ theme }) => theme.menu.background};
        }
      }
    }
  }
`
const Menus = styled.div`
  padding-bottom: 2px;
`
const Right = styled.nav<{ $open: boolean; $mounted: boolean; $scrolled: boolean; $menu: string }>`
  @media (max-width: 799px) {
    position: absolute;
    right: 0;
    top: ${({ $scrolled }) => ($scrolled ? 0 : (LAYOUT.headerExpanded - LAYOUT.headerCollapsed) / 2)}px;
    left: 0;
    text-align: right;
    overflow: hidden;
    transition-duration: ${TRANSITION_DURATION_MS}ms;
    transition-timing-function: ease-in-out;
    transition-property: ${({ $mounted }) => ($mounted ? "height, top" : "none")};
    height: ${({ $open, $menu }) => ($open ? ($menu ? "auto" : "165px") : "60px")};
    ${Menus} {
      background-color: ${({ theme, $mounted }) => ($mounted ? theme.background : "transparent")};
    }
  }
`

interface Data {
  activities: { nodes: Activity[] }
}

const Header: React.FC = () => {
  const { activities } = useStaticQuery<Data>(graphql`
    query {
      activities: allContentfulActivity(sort: { fields: position }) {
        nodes {
          slug
          title
        }
      }
    }
  `)

  const { open, setOpen, mobileOpen, setMobileOpen, ref } = useMenu()
  const mounted = useMounted()
  const scrolled = useScrolled()

  return (
    <Placeholder>
      <Container $mounted={mounted} $scrolled={scrolled}>
        <Content>
          <Link to="/" aria-label="Agence Phare : retour à l'accueil">
            <Logo />
          </Link>
          <Right ref={ref} $open={mobileOpen} $menu={open} $mounted={mounted} $scrolled={scrolled}>
            <Hamburger
              $active={mobileOpen}
              onClick={() => {
                if (mobileOpen) {
                  setOpen(null)
                }
                setMobileOpen(!mobileOpen)
              }}
            />
            <Menus>
              {ENTRIES.map(({ id, title, links }) => {
                const prefix = links === "activities" ? "/activites" : ""

                if (links === "activities") {
                  links = activities.nodes
                }

                return (
                  <Menu key={id} $open={open === id}>
                    <button
                      data-title={title.toUpperCase()} // for bold width calculation
                      onClick={() => {
                        setOpen(open === id ? null : id)
                      }}
                    >
                      {title}
                    </button>
                    <ul>
                      {links.map((link) => (
                        <li key={link.slug}>
                          <Link to={prefix + `/${link.slug}/`} activeClassName="active">
                            {link.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Menu>
                )
              })}
            </Menus>
          </Right>
        </Content>
      </Container>
    </Placeholder>
  )
}

export default Header
