import { useState, useRef, useEffect } from "react"

import { LAYOUT, TRANSITION_DURATION_MS } from "src/layout/constants"

export const useMenu = () => {
  const [open, setOpen] = useState<string>()
  const [mobileOpen, setMobileOpen] = useState(false)
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    const handleClickAway = ({ target }) => {
      if (!ref.current.contains(target)) {
        setOpen(null)
        setMobileOpen(false)
      }
    }

    // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/ClickAwayListener/ClickAwayListener.js
    document.addEventListener("touchstart", handleClickAway)
    document.addEventListener("click", handleClickAway)
    return () => {
      document.removeEventListener("touchstart", handleClickAway)
      document.removeEventListener("click", handleClickAway)
    }
  }, [])

  return { open, setOpen, mobileOpen, setMobileOpen, ref }
}

export const useScrolled = () => {
  const [scrolled, setScrolled] = useState(false)

  const handleScroll = () => {
    setScrolled(window.scrollY > LAYOUT.headerExpanded - LAYOUT.headerCollapsed)
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return scrolled
}

export const useMounted = () => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMounted(true)
    }, TRANSITION_DURATION_MS)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return mounted
}
