import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export interface Props {
  title?: string
  description?: string
  url?: string
  image?: string
}

const SEO: React.FC<Props> = ({ title, description }) => {
  const {
    site: { siteMetadata: data },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          locale
        }
      }
    }
  `)

  const htmlAttr = {
    lang: data.locale.substr(0, 2),
  }
  const pageTitle = title ? `${title} | ${data.title}` : data.title

  return (
    <Helmet htmlAttributes={htmlAttr}>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta name="description" property="og:description" content={description || data.description} />
      <meta property="og:image" content={`${process.env.GATSBY_URL}/logo.png`} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta property="og:locale" content={data.locale} />
      <meta property="og:site_name" content={data.title} />
    </Helmet>
  )
}

export default SEO
