import styled from "styled-components"
import { Link } from "gatsby"

import Logo from "src/svg/logo.svg"
import { LAYOUT } from "src/layout/constants"

const Container = styled.footer`
  max-width: ${LAYOUT.maxWidth}px;
  margin: 0 auto;
  padding: ${LAYOUT.margin}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 0.8em;
  }
  svg {
    width: 120px;
  }
  @media (max-width: 799px) {
    font-size: 0.8em;
  }
`

const Footer: React.FC = () => {
  return (
    <Container>
      <Link to="/legal/">Mentions légales</Link>
      <Logo />
    </Container>
  )
}

export default Footer
