import styled, { createGlobalStyle, DefaultTheme, ThemeProvider } from "styled-components"

import SEO, { Props as SEOProps } from "./seo"
import Header from "./header"
import Footer from "./footer"
import { COLORS } from "./constants"

type ThemeName = "dark" | "blue" | "pink" | "green"

const BACKGROUND: Record<ThemeName, string> = {
  dark: COLORS.sherpa,
  blue: COLORS.lightBlue,
  green: COLORS.lightGreen,
  pink: COLORS.lightPink,
}

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
  svg.logo {
    fill: ${({ theme }) => theme.color};
  }
`

const Main = styled.main`
  min-height: 90vh;
  @media (max-width: 599px) {
    font-size: 0.8em;
  }
`

interface Props {
  theme: ThemeName
}

const Layout: React.FC<Props & SEOProps> = ({ children, theme, ...props }) => {
  const themeObject: DefaultTheme = {
    color: theme === "dark" ? COLORS.white : COLORS.sherpa,
    background: BACKGROUND[theme],
    title: theme === "blue" ? COLORS.pink : COLORS.sherpa,
    menu: {
      inactive: theme === "dark" ? COLORS.pink : COLORS.sherpa,
      active: theme === "dark" ? COLORS.lightPink : COLORS.sherpa,
      background: theme === "dark" || theme === "pink" ? COLORS.pink : COLORS.lightPink,
    },
  }

  return (
    <ThemeProvider theme={themeObject}>
      <SEO {...props} />
      <GlobalStyles />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
